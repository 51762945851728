import { asyncWrap, safeFetchAPI } from '@/api';
import { utils } from '@/helpers';
import React from 'react';

export default function useFeatureFlagEnabled(flag: string) {
	const [enabled, setEnabled] = React.useState(false);

	React.useEffect(() => {
		asyncWrap(async () => {
			// const [enabled, err] = await safeFetchAPI(`${location.origin}/posthog/feature/${flag}?distinctID=${utils.auth.getGlobalID() || utils.auth.getEmail()}`);
			// if (err) {
			// 	console.error(err);
			// } else {
			// 	setEnabled(enabled);
			// }
		});
	}, [flag]);

	return enabled;
}
